/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import * as assign from 'lodash/assign';
import * as ReactDom from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { IStepProps } from '@inwink/registration';
import { translateBag } from '@inwink/i18n';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { parse, stringify } from '@inwink/utils/querystring';
import { RegistrationFinalLinkedinCommunity } from '@inwink/registration/registrationfinal.linkedincommunity';

import type { States } from '@@services/services';
import { AppTextLabel, DynLabel } from '@@services/i18nservice';
import { ItemTemplate } from "@@components/templates/itemtemplate";
import { BlocActions } from "@@components/dynamicpage/common.actions";
import { withAppTrads } from '@@components/apptrads';
import { userMessageModule } from '@@routes/appmodules';
import { wrapReduxStore } from '@@store/index';

import './registrationfinal.less';

const registrationTrads = require('@inwink/tradscompanion/registration.json');

interface IRegistrationFinalBlocDefinition {
    key: string;
    bloc: React.ReactNode;
}

interface IRegistrationFinalOptions {
    html: VisualTheme.IAppLabel;
    template: any;
    registerActions: VisualTheme.IBlocTemplateAction[];
    linkedInCommunity: any;
}

interface IPersonCreateFinalData {
    registered: any;
    collaborators: any[]
    stepsErrors: any;
    steps: any[];
}

interface IRegistrationFinalProps extends
    IStepProps<IRegistrationFinalOptions, IPersonCreateFinalData> {
    eventstate: States.IEventState;
    communitystate: States.ICommunityState;
    userstate: States.IAppUserState;
    // location?: any;
    history?: any;
    hasCustomInteractiveBlocs?: boolean;
    enrichMessageContent?: (content: IRegistrationFinalBlocDefinition[]) => void;
    enrichInteractiveBlocs?: (content: IRegistrationFinalBlocDefinition[]) => void;
}

interface IRegistrationFinalState {
    autoLoginRedirectUrl?: string;
}

@withAppTrads(registrationTrads)
class RegistrationFinalComponent extends React.Component<IRegistrationFinalProps, IRegistrationFinalState> {
    autoLogin;

    loginRedirectUrl: string;

    hasInteractiveElements: boolean;

    options: IRegistrationFinalOptions;

    constructor(props: IRegistrationFinalProps) {
        super(props);

        const search = props.location?.search;
        if (search) {
            const args = parse(search);
            this.loginRedirectUrl = args?._irurl;
        }

        const step = props.step?.step;
        this.options = step?.properties;
        this.hasInteractiveElements = props.hasCustomInteractiveBlocs
            || this.options?.linkedInCommunity
            || this.options?.registerActions?.length > 0
            || (step as any)?.registerActions?.length > 0; // for backward compatibility
    }
    
    componentDidMount() {
        if (this.autoLogin) {
            clearTimeout(this.autoLogin);
            this.autoLogin = null;
        }

        const location = this.props.location;
        const query = parse(location.search, null);
        const newquery = {
            registrationstep: "confirmed"
        };
        Object.assign(newquery, query);
        newquery.registrationstep = "confirmed";
        this.props.history.push(location.pathname + "?" + stringify(newquery));
        // eslint-disable-next-line react/no-find-dom-node
        const pageElt = ReactDom?.findDOMNode(this) as HTMLElement;
        if (pageElt) {
            pageElt?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

        if (this.loginRedirectUrl && !this.hasInteractiveElements) {
            this.autoLogin = setTimeout(() => {
                this.login();
            }, 3 * 1000);
        }
    }

    componentWillUnmount() {
        if (this.autoLogin) {
            clearTimeout(this.autoLogin);
            this.autoLogin = null;
        }
    }

    renderLinks = () => {
        let actions = this.options?.registerActions;

        // for backward compatibility
        if (!actions && this.props.step && this.props.step.step && (this.props.step.step as any).registerActions) {
            actions = (this.props.step.step as any).registerActions;
        }

        if (actions && actions.length) {
            return <BlocActions
                blocState={this.props.blocState}
                event={this.props.eventstate}
                community={this.props.communitystate}
                bloctemplate={this.props.blocTemplate}
                update={this.props.updateData}
                i18n={this.props.i18n}
                actions={actions}
                datacontext={this.props.datacontext}
                page={this.props.page}
                rootwebsite={this.props.rootwebsite}
                urlservice={this.props.urlservice}
                user={this.props.userstate}
                pageActions={this.props.pageActions}
                location={this.props.location}
                theme={this.props.theme}
                template={this.props.template}
                visualstate={this.props.visualstate}
            />;
        }
    };

    getEndMessage = () => {
        let endMessage = {};
        if (this.props.properties.steps && this.props.properties.steps.length) {
            const step = this.props.properties.steps.find((s) => {
                return s.type === "personcreate";
            });
            if (step && step.labels) {
                const keys = Object.keys(step.labels);
                keys.map((key) => {
                    endMessage = assign({}, endMessage, {
                        [key]: step.labels[key]["registration.end"]
                    });
                    return null;
                });
                return endMessage;
            } if (this.props.step?.step?.labels) {
                const keys = Object.keys(this.props.step.step.labels);
                keys.map((key) => {
                    endMessage = assign({}, endMessage, {
                        [key]: this.props.step.step.labels[key]["registration.end"]
                    });
                    return null;
                });
                return endMessage;
            }
        }
    };

    login = () => {
        userMessageModule().then((mod) => {
            const store = (this.props as any).store;
            const email = this.props.data?.registered?.mail || this.props.data?.registered?.email;
            mod.loginActions.showLogin(null, this.loginRedirectUrl,
                email)(store.dispatch, store.getState);
        });
    };

    renderBlocActionsPart() {
        let loginRedirectContent: React.JSX.Element;
        if (this.loginRedirectUrl) {
            const messagei18n = this.hasInteractiveElements
                ? 'registration.registrationfinal.connectredirect'
                : 'registration.registrationfinal.autoredirect';

            loginRedirectContent = <div className='autoredirect'>
                <AppTextLabel component='p' i18n={messagei18n} />
                <div className="bloc-actions">
                    <button type="button" onClick={this.login}>
                        <AppTextLabel i18n="registration.registrationfinal.autoredirect.login" />
                    </button>
                </div>
            </div>;
        }

        return <>
            {loginRedirectContent}
            {this.renderLinks()}
        </>;
    }

    render() {
        let content: React.JSX.Element;
        if (this.options?.html) {
            content = <DynLabel component="div" i18n={this.options.html} className="message" inject={this.props.data} />;
        } else if (this.options?.template) {
            content = <div>
                <ItemTemplate
                    user={this.props.userstate.currentUser}
                    i18n={this.props.i18n}
                    template={this.options.template}
                    datacontext={this.props.datacontext}
                    data={this.props.data}
                />
            </div>;
        } else {
            const labels = this.getEndMessage();
            const message = translateBag(this.props.i18n, labels);
            if (message) {
                content = <div><DynLabel i18n={labels} inject={this.props.data} /></div>;
            }
        }

        const messageContents = [
            { "key": "defaultContent", "bloc": content }
        ];

        const interactiveBlocs = [
            { "key": "actions", "bloc": this.renderBlocActionsPart()}
        ];

        if (this.options?.linkedInCommunity) {
            interactiveBlocs.push({
                "key": "linkedIn",
                "bloc": <RegistrationFinalLinkedinCommunity 
                    configuration={this.options.linkedInCommunity} userdata={this.props.data} />
            });
        }

        this.props.enrichMessageContent?.(messageContents);
        this.props.enrichInteractiveBlocs?.(interactiveBlocs);

        return <div className="createperson-final">
            <div className="message">
                {messageContents.map((blocDef) => <div key={blocDef.key}>{blocDef.bloc}</div>)}
            </div>
            {interactiveBlocs.map((blocDef) => <div key={blocDef.key}>{blocDef.bloc}</div>)}
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        userstate: state.user,
        eventstate: state.event,
        communitystate: state.community
    };
}

export const RegistrationFinal: React.ComponentClass<IRegistrationFinalProps> = connect(
    mapStateToProps,
    null
)(withRouter(wrapReduxStore(RegistrationFinalComponent) as any)) as any;