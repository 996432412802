/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { connect } from 'react-redux';
import { IStepProps, RegistrationStepNavbar } from '@inwink/registration';
import { getPredicate } from '@inwink/expressions';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getBlocTemplateFor } from '@@services/itemshelpers';
import type { States } from '@@services/services';
import { ITeaserItemsSort, sortItems } from '@@components/dynamicpage/teaseritems.props';
import { ExhibitorGroup, IExhibitorGroupProps } from './exhibitorgroup';

import './index.less';
import { IPersonRegisterStepStateData, setCurrentUserMailOnPatch } from '../registrationsteps.helper';

export interface IExhibitorGroup {
    filter? : any;
    sort?: ITeaserItemsSort;
    query? : any;
    title : VisualTheme.IAppLabel;
    description : VisualTheme.IAppLabel;
    minSelected? : number;
    maxSelected? : number;
    showIf: any[];
    predicate?: any;
    template : string;
    registered?: string[];
    exhibitors?: Entities.IExhibitor[];
    itemtemplate?: any;
}

export interface IPersonRegisterExhibitorStepOptions {
    exhibitorGroups: IExhibitorGroup[];
    template : string;
}

export interface IPersonRegisterExhibitorStepData extends IPersonRegisterStepStateData {
    registered: {
        exhibitorsToRegister: string[];
    };
    registeredPatch: {
        mail: string;
        exhibitorsToRegister: string[];
    };
    operation : string;
}

export interface IPersonRegisterExhibitorStepProps
    extends IStepProps<IPersonRegisterExhibitorStepOptions, IPersonRegisterExhibitorStepData> {
    eventstate: States.IEventState;
    userstate: States.IAppUserState;
}

export interface IPersonRegisterExhibitorStepState {
    defaultItemtemplate : any;
    groups : IExhibitorGroup[];
    mustSelect: boolean;
}

export function computeExhibitorGroup(stepProperties: any, registered: any, exhibitors: Entities.IExhibitor[],
    event: Entities.IEventDetail, i18n: Entities.i18nService)
    : { groups: IExhibitorGroup[], mustSelect: boolean } {
    const groups = stepProperties?.exhibitorGroups?.filter((_g) => {
        const g = _g;
        if (!g.showIf) {
            return true;
        }

        if (!g.predicate) {
            g.predicate = getPredicate(g.showIf);
        }

        return g.predicate(registered);
    }) || [];

    let mustSelect = false;
    groups.forEach((_g) => {
        const g = _g;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let exhibitorPredicate = (session: Entities.IExhibitor) => {
            return true;
        };
        if (g.filter) {
            exhibitorPredicate = getPredicate(g.filter);
        }

        let groupExhibitors = exhibitors.filter((exhibitor) => {
            return exhibitorPredicate(exhibitor);
        });

        if (g.sort?.propName) {
            groupExhibitors = sortItems(groupExhibitors, i18n.currentLanguageCode, g.sort);
        }

        if (groupExhibitors?.length > 50) {
            groupExhibitors.length = 50;
        }

        if (groupExhibitors) {
            g.registered = registered?.exhibitorsToRegister
                && registered.exhibitorsToRegister.map((sid) => {
                    return groupExhibitors.filter((s) => s.id === sid)[0];
                }).filter((s) => !!s).map((s) => s.id);
        }

        g.exhibitors = groupExhibitors || [];
        g.registered = g.registered || [];

        if (g.minSelected && g.registered.length < g.minSelected) {
            mustSelect = true;
        }
    });

    return {
        groups: groups,
        mustSelect
    };
}

class PersonRegisterExhibitorStepComponent
    extends React.Component<IPersonRegisterExhibitorStepProps, IPersonRegisterExhibitorStepState> {
    constructor(props: IPersonRegisterExhibitorStepProps) {
        super(props);
        let template = this.props.step?.step?.properties?.template;
        if (!template) {
            template = "exhibitoritem";
        }

        const compute = computeExhibitorGroup(this.props.step?.step?.properties,
            this.props.data?.registered, this.props.eventstate.data.exhibitors.data, this.props.event, this.props.i18n);

        this.state = {
            groups: compute.groups,
            mustSelect: compute.mustSelect,
            defaultItemtemplate: template ? getBlocTemplateFor(props.eventstate.data, template) : null
        };
    }

    componentDidMount(): void {
        setCurrentUserMailOnPatch(this.props);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    UNSAFE_componentWillReceiveProps(nextprops: IExhibitorGroupProps) {
        if (nextprops.data.registered !== this.props.data.registered) {
            let mustSelect = false;
            const groups = this.state.groups && this.state.groups.map((g) => {
                if (g.exhibitors) {
                    let registered = nextprops.data && nextprops.data.registered && nextprops.data.registered.exhibitorsToRegister
                        && nextprops.data.registered.exhibitorsToRegister.map((sid) => {
                            return g.exhibitors.filter((s) => s.id === sid)[0];
                        }).filter((s) => !!s).map((s) => s.id);
                    registered = registered || [];
                    if (g.minSelected && registered.length < g.minSelected) {
                        mustSelect = true;
                    }

                    return Object.assign({}, g, {
                        registered: registered
                    });
                }

                return g;
            });
            this.setState(() => {
                return {
                    groups: groups,
                    mustSelect: mustSelect
                };
            });
        }
    }

    exhibitorClicked = (exhibitorId: string) => {
        const newExhibitorsToRegister = this.props.data.registered && this.props.data.registered.exhibitorsToRegister
            ? [...this.props.data.registered.exhibitorsToRegister] : [];
        const idx = newExhibitorsToRegister.indexOf(exhibitorId);
        if (idx >= 0) {
            newExhibitorsToRegister.splice(idx, 1);
        } else {
            newExhibitorsToRegister.push(exhibitorId);
        }
        this.props.updateData({
            registered: Object.assign({}, this.props.data.registered, {
                exhibitorsToRegister: newExhibitorsToRegister
            }),
            registeredPatch: Object.assign({}, this.props.data.registeredPatch, {
                exhibitorsToRegister: newExhibitorsToRegister
            })
        });
    };

    render() {
        const groupsCtrls = this.state.groups && this.state.groups.map((g, idx) => {
            return <ExhibitorGroup
                {...this.props}
                key={"group" + idx}
                group={g}
                groupIndex={idx}
                defaultItemtemplate={this.state.defaultItemtemplate}
                onExhibitorClicked={this.exhibitorClicked}
            />;
        });

        return <div className="registration-registerexhibitorstep">
            <section id="exhibitors-list">
                {groupsCtrls}
            </section>
            <RegistrationStepNavbar {...this.props} allowPrevious={true} allowNext={true} disableNext={this.state.mustSelect} />
        </div>;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        userstate: state.user,
        eventstate: state.event
    };
}

// eslint-disable-next-line max-len
export const PersonRegisterExhibitorStep : React.Component<IPersonRegisterExhibitorStepProps> = connect(mapStateToProps, null)(PersonRegisterExhibitorStepComponent as any) as any;
