/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { Entities } from '@inwink/entities/entities';
import { DynLabel } from '@@services/i18nservice';
import { getBlocTemplateFor, getItemsProperties } from '@@services/itemshelpers';
import { ContentStyle } from '@@components/contentstyle';
import type { IExhibitorGroup, IPersonRegisterExhibitorStepProps } from '.';

import { ExhibitorGroupItem } from './exhibitorgroup.item';
import { ItemTemplateProvider } from '@@components/templates/itemtemplateprovider';

export interface IExhibitorGroupProps extends IPersonRegisterExhibitorStepProps {
    group: IExhibitorGroup;
    groupIndex : number;
    defaultItemtemplate: any;
    onExhibitorClicked: (sessionid: string) => void;
}

interface IExhibitorGroupState {
    exhibitors: Entities.IExhibitor[];
    itemtemplate : any;
    itemsAlign : string;
    itemsLayout: string;
}

export class ExhibitorGroup extends React.Component<IExhibitorGroupProps, IExhibitorGroupState> {
    constructor(props : IExhibitorGroupProps) {
        super(props);
        const exhibitors = props.group.exhibitors;
        let itemtemplate = props.defaultItemtemplate;

        if (props.group?.itemtemplate) {
            itemtemplate = getBlocTemplateFor(props.eventstate?.data, props.group.itemtemplate);
        }

        const properties = getItemsProperties("", props.visualstate,
            props.step?.step?.properties, itemtemplate, null, null);

        this.state = {
            exhibitors: exhibitors,
            ...properties
        };
    }

    getTemplate(templatename: string) {
        if (this.state.itemtemplate && this.state.itemtemplate[templatename]) {
            return this.state.itemtemplate[templatename];
        }
    }

    getTemplateProperties() {
        return this.props.template.properties;
    }

    render() {
        let exhibitors = null;
        let itemstyle = null;
        let template = this.state.itemtemplate as any;
        if (template && template.template) { // dans ce cas on est sur un IItemTemplateConfig
            if (template.customCSS) {
                itemstyle = <ContentStyle
                    css={template.customCSS}
                    blocid={"grp" + this.props.groupIndex}
                    contentid={"grpcontent" + this.props.groupIndex}
                    theme={null}
                />;
            }

            template = template.template;
        }

        const canSelect = (!this.props.group.maxSelected || this.props.group.registered.length < this.props.group.maxSelected);

        if (this.state.exhibitors?.length) {
            exhibitors = this.state.exhibitors.map((e) => {
                return <ItemTemplateProvider provider={this} key={e.id}>
                    <ExhibitorGroupItem
                        {...this.props}
                        key={e.id}
                        canSelect={canSelect}
                        exhibitor={e}
                        defaultItemtemplate={template}
                        templateOptions={this.state.itemtemplate?.templateOptions}
                        onExhibitorClicked={this.props.onExhibitorClicked}
                    />
                </ItemTemplateProvider>;
            });
        } else {
            return null;
        }

        const itemsLayout = this.state.itemsLayout || "ukn";
        const itemsAlign = this.state.itemsAlign || "ukn";

        return <div className="exhibitor-group" id={"grp" + this.props.groupIndex}>
            {itemstyle}
            <header>
                {this.props.group.title
                    ? <DynLabel component="h4" className="exhibitor-group-title" i18n={this.props.group.title} /> : null }
                {this.props.group.description
                    ? <DynLabel component="p" className="exhibitor-group-description" i18n={this.props.group.description} />
                    : null }
            </header>
            <section id={"ct-grpcontent" + this.props.groupIndex}>
                <div className={"inwink-items bloc-content layout-" + itemsLayout + " align-" + itemsAlign}>{exhibitors}</div>
            </section>
        </div>;
    }
}
