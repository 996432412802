/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { Entities } from '@inwink/entities/entities';
import { ItemTemplate } from '../../../../../../../components/templates/itemtemplate';
import type { IExhibitorGroupProps } from './exhibitorgroup';

interface IExhibitorGroupItemProps extends IExhibitorGroupProps{
    exhibitor : Entities.IExhibitor;
    canSelect : boolean;
    templateOptions: any;

}

export class ExhibitorGroupItem extends React.Component<IExhibitorGroupItemProps, any> {
    exhibitorClicked = () => {
        this.props.onExhibitorClicked(this.props.exhibitor.id);
    };

    render() {
        const e = this.props.exhibitor;
        const isSelected = this.props.data.registered && this.props.data.registered.exhibitorsToRegister
            && this.props.data.registered.exhibitorsToRegister.indexOf(e.id) >= 0;
        const click = this.props.canSelect || isSelected ? this.exhibitorClicked : null;

        return <div
            className={"inwink-item exhibitor-item"
                + (isSelected ? " selected bloc-accentborder" : "")}
            id={'item-' + e.id}
        >
            <div className="itemcontent">
                <ItemTemplate
                    template={this.props.defaultItemtemplate}
                    contentEditable={false}
                    user={this.props.userstate.currentUser}
                    i18n={this.props.i18n}
                    datacontext={{
                        ...this.props.datacontext,
                        entity: e,
                        templateOptions: this.props.templateOptions,
                        customcontext: {
                            isSelected,
                            onRegistrationExhibitorClicked: click
                        }
                    }}
                    data={e}
                />
            </div>
        </div>;
    }
}
