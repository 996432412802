import type { IStepProps } from "@inwink/registration";

export interface IPersonRegisterStepStateData {
    registered: object;
    registeredPatch: {
        mail: string;
    };
}

export function setCurrentUserMailOnPatch(props: IStepProps<any, IPersonRegisterStepStateData>) {
    const { data, updateData } = props;
    const registeredPatch = data?.registeredPatch ?? {};

    if (!Object.prototype.hasOwnProperty.call(registeredPatch, "mail")) {
        const currentUserMail = props.datacontext?.userstate?.currentUser?.detail?.mail;
        updateData({registeredPatch: { ...registeredPatch, mail: currentUserMail }});
    }
}