import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    IRegistrationDatasource,
    IRegistrationStatus,
    RegistrationStepNavbar,
    registrationStepsCatalog
} from '@inwink/registration';
import { Loader } from '@inwink/loader';
import { MultistepRegistration } from '@inwink/registration/multistepregistration';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import type { IFileInputManager } from '@inwink/fileutils/fileapi';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { PersonRegistrationCreate, PersonRegistrationCreateProps } from '@inwink/registration/personregistration/create';
import { PagedEntityFormNavigationProps } from '@inwink/entityform/pagedentityform';
import { withAppTrads } from "@@components/apptrads";
import { IDynamicBlocProps, DynamicBloc } from '../../common';
import { BlocTitle, BlocContent } from "../../common.title";
import { BlocActions } from "../../common.actions";
import { States } from '../../../../services/services';
import { AppTextLabel } from '../../../../services/i18nservice';
import { IInwinkStore, wrapReduxStore } from '../../../../store';
import { RegistrationFinal } from './registrationfinal';
import { computeExhibitorGroup } from '@@event/features/user/blocs/registrationsteps/registerexhibitor';
import { getPredicate } from '@inwink/expressions';

import './bloc.multistepregistration.less';

const defaultRegistrationTrads = require('@inwink/tradscompanion/registration.json');
const networkingTrads = require('@inwink/tradscompanion/networking.json');

registrationStepsCatalog.Person.personcreatefinal = {
    component: RegistrationFinal
};

registrationStepsCatalog.Person.registrationfinal = {
    component: RegistrationFinal
};

registrationStepsCatalog.Person.personcreatedata = {
    component: function cmpt(props: PersonRegistrationCreateProps) {
        return <PersonRegistrationCreate
            {...props}
            noDataCreation={true}
            pagedEntityFormNavBarComponent={(_props: PagedEntityFormNavigationProps) => {
                // normalement on ne devrait plus avoir de formulaire avec plusieurs page, mais on gère quand même le cas
                const hasPrevious = _props.showPrevious || props.status.hasPrevious;
                const hasNext = _props.showNext || _props.showValidate || props.status.hasNext;
                return <RegistrationStepNavbar
                    {...props}
                    allowPrevious={hasPrevious}
                    allowNext={hasNext}
                    onBeforePrevious={() => {
                        return new Promise((resolve) => {
                            if (_props.showNext) {
                                // on va juste faire un previous dans le formulaire et non dans le step
                                _props.onNext();
                                resolve(false);
                            } else {
                                resolve(true);
                            }
                        });
                    }}
                    onBeforeNext={() => {
                        return new Promise((resolve) => {
                            if (_props.showValidate) {
                                _props.onValidate();
                            } else if (_props.showNext) {
                                // on va juste faire un next dans le formulaire et non dans le step
                                _props.onNext();
                            }
                            resolve(false);
                        });
                    }}
                />;
            }}
        />;
    }
};

export interface IBlocMultistepRegistrationState {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function blocMultistepRegistrationData(page: States.ICurrentPageState, blocdata: IBlocMultistepRegistrationState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bloc: VisualTheme.IBlocContentTemplateBase, state: States.IAppState, dispatch: (action) => void) {
}

export interface ICustomRegistrationActions {
    enabled: boolean;
    runAction: (registered: any) => Promise<any>;
}

export interface IBlocMultistepRegistrationProps extends IDynamicBlocProps<IBlocMultistepRegistrationState> {
    i18nHelper?: Entities.i18nHelper;
    history?: any;
    store?: IInwinkStore;
    registeredFieldTemplate: Entities.IFieldTemplate;
    datasource: IRegistrationDatasource;
    registeredCreated: (registered: any) => void;
    updateSteps?: (steps: any[]) => void;
    fileInputManager: IFileInputManager;
    expirationDateTimezone?: string;
    expirationDate?: string | Date;
    keepLiveAfterExpirationDate?: boolean;
    steps: any[];
    initialData?: {
        registered: any,
        registeredPatch: any
    };
    customRegistrationActions?: Record<string, ICustomRegistrationActions>;
    customComponentProvider?: (fieldtemplate: Entities.IEntityFieldTemplate,
        formtemplate: Entities.IExtendedFieldsFormFieldTemplate, entityName: string) => any;
    defaultPhoneCountryCode?: string;
}

interface IMultistepState {
    showContent: boolean;
    hasNext: boolean;
    steps: any[];
    isEditMode: boolean;
    currentStepIx?: number;
    personsenddataStepIx?: number;
    initialData?: any;
    data?: any;
    error?: string;
    buyerformtemplate?: Entities.IExtendedFieldsFormTemplate;
}

@withAppTrads(defaultRegistrationTrads, networkingTrads)
class BlocMultistepRegistrationComponent extends React.Component<IBlocMultistepRegistrationProps, IMultistepState> {
    constructor(props: IBlocMultistepRegistrationProps) {
        super(props);
        const { steps, personsenddataStepIx } = this.checkSteps(props.user?.currentUser?.detail);
        let initialData = Object.assign({}, this.props.initialData);
        const res = this.generateInitialData(props.user, initialData, props.registeredFieldTemplate);
        if (res.process) {
            initialData = res.data;
        }

        this.state = {
            isEditMode: false,
            currentStepIx: 0,
            showContent: false,
            hasNext: true,
            personsenddataStepIx,
            steps: steps,
            initialData: initialData,
        };
    }

    checkSteps = (userDetail: any, forcedSteps?: any[]) => {
        let steps = forcedSteps?.length ? [...forcedSteps] : (this.props.steps?.length ? [...this.props.steps] : []);
        let personsenddataStepIx;
        const currentStepIndex = this.state?.currentStepIx;
        const currentStepId = this.state?.steps[currentStepIndex]?.id;
        if (steps?.length) {
            if (this.state?.isEditMode) {
                steps = steps.filter((s) => s.type !== "personcollaborators" && s.type !== "personinvitations");
            }
            steps = steps.filter((s) => {
                if (s.showIf) {
                    const step = s;
                    const pr = getPredicate(s.showIf);
                    step.showIfPredicate = pr;
                    return step.showIfPredicate({ entity: userDetail });
                }
                return true;
            });
            if ((steps[currentStepIndex]?.id !== currentStepId) && !forcedSteps?.length) {
                // Si une étape se rajoute avant l'étape actuelle, nous gardons les étapes telles quelles sont #52630
                steps = this.state.steps;
            }
            const personsenddataStep = steps.filter((s) => s.type === "personsenddata")[0];
            const registerExhibitorSteps = steps.filter((s) => s.type === "personregisterexhibitor");

            // je commente ce code puisque ça provoque un effet de bord et un bug sur 
            // const registerSessionSteps = steps.filter((s) => s.type === "personregistersession");
            // if (registerSessionSteps?.length) {
            //     registerSessionSteps.map((registerSessionStep) => {
            //         const registerSessionStepIx = steps.indexOf(registerSessionStep);
            //         // on regarde si la step session aura des sessions a afficher
            //         const compute = computeSessionGroup(registerSessionStep.properties,
            //             userDetail, this.props.event.data.sessions.data,
            //             this.props.event.detail, this.props.i18n);
            //         const hasData = compute.groups && compute.groups.some((g) => {
            //             return g.sessions?.length > 0;
            //         });
            //         if (!hasData) {
            //             // si ce n'est pas le cas, on supprime l'étape
            //             steps.splice(registerSessionStepIx, 1);
            //         }
            //     });
            // }
            if (registerExhibitorSteps?.length) {
                registerExhibitorSteps.map((registerExhibitorStep) => {
                    const registerExhibitorStepIx = steps.indexOf(registerExhibitorStep);
                    // on regarde si la step exhibitor aura des exhibitors a afficher
                    const compute = computeExhibitorGroup(registerExhibitorStep.properties,
                        userDetail, this.props.event.data.exhibitors.data,
                        this.props.event.detail, this.props.i18n);
                    const hasData = compute.groups && compute.groups.some((g) => {
                        return g.exhibitors?.length > 0;
                    });
                    if (!hasData) {
                        // si ce n'est pas le cas, on supprime l'étape
                        steps.splice(registerExhibitorStepIx, 1);
                    }
                });
            }
            if (personsenddataStep) {
                personsenddataStepIx = steps.indexOf(personsenddataStep);
                // on va set le du next de l'étape précédente à send pour que
                // l'utilisateur comprenne bien qu'il va envoyer son inscription
                const previousStepIx = personsenddataStepIx - 1;
                if (previousStepIx > -1) {
                    steps = steps.map((s) => {
                        return Object.assign({}, s, { nextLabel: null });
                    });
                    steps[previousStepIx].nextLabel = "actions.save";
                }
            }
        }
        return { steps, personsenddataStepIx };
    };

    componentDidMount() {
        this.setState({ showContent: true });
    }

    generateInitialData(user: States.IAppUserState, initialData: any,
        fieldTemplate: Entities.IFieldTemplate) {
        let initData = initialData;
        let process = false;
        if (user?.currentUser
            && !user.currentUser.isRegistered
            && (user.currentUser.detail as any).inwinkcustomercontact) {
            const customerContactData = (user.currentUser.detail as any).inwinkcustomercontact;
            const newData: any = {};
            fieldTemplate.template.fields.forEach((field) => {
                // on désactive la copie d'id et des champs techniques
                if (field.type !== "Guid" && !(field as any).metadata?.isTechnical) {
                    if (Object.prototype.hasOwnProperty.call(customerContactData, field.key)) {
                        newData[field.key] = customerContactData[field.key];
                    }
                }
            });
            if (Object.keys(newData).length) {
                initData = Object.assign({}, initialData, {
                    registered: Object.assign({}, initialData?.registered, newData),
                    registeredPatch: Object.assign({}, initialData?.registered, newData)
                });
                process = true;
            }
        }

        if (user?.currentUser?.detail && this.state?.buyerformtemplate && !this.state?.initialData?.buyer) {
            const buyer: any = {};
            const fields = this.getAllFields(this.state.buyerformtemplate);
            fields.forEach((field) => {
                const fieldValue = user.currentUser.detail[field.key];
                if (fieldValue) {
                    buyer[field.key] = fieldValue;
                }
            });
            if (Object.keys(buyer).length > 0) {
                initData = Object.assign({}, initialData, {
                    buyer: Object.assign({}, initialData?.buyer, buyer),
                });
                process = true;
            }
        }

        return { process: process, data: initData };
    }

    componentDidUpdate(prevprops: IBlocMultistepRegistrationProps, prevState: IMultistepState) {
        if (this.props.user !== prevprops.user
            || this.props.steps !== prevprops.steps
            || this.state.isEditMode !== prevState.isEditMode
            || this.state.buyerformtemplate !== prevState.buyerformtemplate) {
            const patch: Partial<IMultistepState> = {};
            const res = this.generateInitialData(this.props.user, this.state.initialData,
                this.props.registeredFieldTemplate);
            if (res.process) {
                patch.initialData = res.data;
            }
            const { steps, personsenddataStepIx } = this.checkSteps(this.props.user?.currentUser?.detail);
            patch.steps = steps;
            patch.personsenddataStepIx = personsenddataStepIx;
            this.setState(patch as any);
        }
    }

    getAllFields(group: Entities.IExtendedFieldsFormGroupTemplate): Entities.IExtendedFieldsFormFieldTemplate[] {
        const f = [];
        if (group) {
            if (group.groups)
                group.groups.forEach(grp => {
                    const res = this.getAllFields(grp);
                    if (res) {
                        f.push(...res);
                    }
                });
            if (group.fields)
                group.fields.forEach((fld) => {
                    f.push(fld);
                });
        }
        return f;
    }

    dataupdated = (data: any, status: IRegistrationStatus) => {
        const patch: Partial<IMultistepState> = {};
        // if (data && data.registered 
        //     // && this.props.user
        //     // && this.props.user.currentUser
        //     && status?.currentStep === this.state.personsenddataStepIx) {
        //     // on sauvegarde les données du user uniquement si on reçoit l'update depuis l'étape d'envoie des données
        //     // ça veut dire que les données ont bien été sauvegardées.
        //     this.props.registeredCreated(data.registered);
        // }
        if (data?.registered) {
            patch.data = data.registered;
        }
        if (data?.buyerformtemplate) {
            patch.buyerformtemplate = data.buyerformtemplate;
        }
        if (status) {
            patch.hasNext = status.hasNext;
            patch.currentStepIx = status.currentStep;
        }
        const { steps, personsenddataStepIx } = this.checkSteps(data?.registered, data?.steps);
        patch.steps = steps;
        patch.personsenddataStepIx = personsenddataStepIx;
        this.setState(patch as any);
    };

    trackActions = (category, action) => {
        if (category == "registration" && action == "registered") {
            this.props.registeredCreated(this.state.data);
        }
    };

    checkExpirationDate = () => {
        if (this.props.keepLiveAfterExpirationDate) {
            return false;
        }

        let hasExpired = false;
        const template = this.props.template;
        const timeZone = this.props.expirationDateTimezone;

        let expirationDate = null;
        if (this.props.expirationDate) {
            if (timeZone) {
                expirationDate = momenttimezone.tz(this.props.expirationDate, timeZone);
            } else {
                expirationDate = moment(this.props.expirationDate);
            }
        }

        if (template?.properties?.limitDate?.date) {
            expirationDate = timeZone
                ? momenttimezone.tz(
                    template.properties.limitDate.date, 'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss',
                    timeZone
                )
                : moment(template.properties.limitDate.date);
        }

        const currentDate = moment().local();
        if (expirationDate && expirationDate.local() && expirationDate.local().isBefore(currentDate, 'minute')) {
            hasExpired = true;
        }

        return hasExpired;
    };

    updateSteps = (steps: any[]) => {
        this.setState({ steps });
    };

    render() {
        let content;
        let actions;
        let registrationErrorActions: JSX.Element;
        const hasExpired = this.checkExpirationDate();


        // ajouter "inwink" dans datacontext pour récup la donnée coté shared
        // et pouvoir vérifier si on est sur un lien sécurisé ou non
        const extendedDataContext = {
            ...this.props.datacontext,
            inwink: {
                ...this.props.datacontext.inwink,
                registration: inwink.registration,
            },
        };

        if (!this.state.hasNext) {
            // On retire les actions du bloc du template pour ne pas qu'elles s'ajoutent en plus des actions du formulaire même.
            const templateWithoutActions = Object.assign({}, this.props.template, { actions: [] });
            actions = <BlocActions
                {...this.props}
                template={templateWithoutActions}
                actions={this.props.bloctemplate.content[0].registerActions}
            />;
        }

        if (!this.props.user.checked) {
            content = <Loader />;
        } else if (hasExpired) {
            let message = <AppTextLabel component="h4" i18n="registration.form.hasexpired" />;
            if (this.props.template.properties
                && this.props.template.properties.limitDate
                && this.props.template.properties.limitDate.message) {
                message = <AppTextLabel
                    component="h4"
                    i18n={this.props.i18nHelper.translateBag(this.props.template.properties.limitDate.message)}
                />;
            }
            if (this.props.template.properties
                && this.props.template.properties.limitDate
                && this.props.template.properties.limitDate.actions) {
                actions = <BlocActions
                    visualstate={this.props.visualstate}
                    update={this.props.update}
                    template={this.props.template.properties.limitDate}
                    bloctemplate={this.props.bloctemplate}
                    theme={this.props.theme}
                    location={this.props.location}
                    blocState={this.props.blocState}
                    pageActions={this.props.template.properties.limitDate.actions}
                    user={this.props.user}
                    urlservice={this.props.urlservice}
                    rootwebsite={this.props.rootwebsite}
                    i18n={this.props.i18n}
                    event={this.props.event}
                    community={this.props.community}
                    page={this.props.page}
                    datacontext={extendedDataContext}
                />;
            }

            content = (
                <div className="expiration-message">
                    {message}
                    {actions}
                </div>
            );
        } else if (this.state.showContent
            && (this.props.event?.detail || this.props.community?.detail)
            && this.state.steps) {
            if (this.state.error === "alreadyregistered") {
                const templateWithoutActions = Object.assign({}, this.props.template, { actions: [] });
                registrationErrorActions = <BlocActions
                    {...this.props}
                    template={templateWithoutActions}
                    actions={this.state?.steps[this.state.steps?.length - 1].alreadyRegisterActions}
                />;
            }
            content = (
                <>
                    <MultistepRegistration
                        page={this.props.page}
                        blocState={this.props.blocState}
                        blocTemplate={this.props.bloctemplate}
                        template={this.props.template}
                        rootwebsite={this.props.rootwebsite}
                        urlservice={this.props.urlservice}
                        pageActions={this.props.pageActions}
                        visualstate={this.props.visualstate}
                        datacontext={extendedDataContext}
                        // multistepregistration ne se sert que de configuration
                        event={(this.props.event?.detail || this.props.community?.detail as any)}
                        fileinputmanager={this.props.fileInputManager}
                        isFullSize={this.props.template.fullsize}
                        displayLanguage={this.props.i18n.currentLanguageCode}
                        datasource={this.props.datasource}
                        actions={this.props.template.actions}
                        registrationErrorActions={registrationErrorActions}
                        i18n={this.props.i18n}
                        i18nHelper={this.props.i18nHelper}
                        steps={this.state.steps}
                        entity={this.props.template.properties.entity}
                        location={this.props.location as any}
                        updateSteps={this.updateSteps}
                        setEditMode={(isEditMode) => this.setState({ isEditMode })}
                        history={this.props.history}
                        params={this.props.match.params}
                        user={this.props.user.currentUser?.detail}
                        dataupdated={this.dataupdated}
                        properties={this.props.template?.properties}
                        userUTM={({ ...global.inwink.utm, ...global.inwink.invitation })}
                        theme={this.props.theme}
                        data={this.state.initialData}
                        customRegistrationActions={this.props.customRegistrationActions}
                        onRegistrationError={(error: string) => this.setState({ error })}
                        customComponentProvider={this.props.customComponentProvider}
                        trackActions={this.trackActions}
                    />
                    {actions}
                </>
            );
        } else {
            content = <Loader />;
        }

        const useDefaultBg = !(this.props.template?.properties?.disableDefaultBg);

        return (
            <DynamicBloc
                {...this.props}
                contentCSSClass={useDefaultBg ? " bloc-defaultbg" : ""}
                className="multistepregistrationbloc"
            >
                <BlocTitle {...this.props} className="bloc-header" />
                <BlocContent {...this.props}>
                    {content}
                    {/* <RegistrationFinalLinkedinCommunity configuration={{
                        apiKey: "86j0cl1b5rpelq",
                        eventId: "7094976723124989955"
                    }} /> */}
                </BlocContent>
            </DynamicBloc>
        );
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user
    };
}

export const BlocMultistepRegistration: new (any) => React.Component<IBlocMultistepRegistrationProps, any> = connect(
    mapStateToProps,
)(withRouter(wrapReduxStore(BlocMultistepRegistrationComponent) as any)) as any;
